<template>
  <div class="id-type">
    <ListItemCheck :text="$locale['id_card_label']" @click="$emit('select', 'idcard')" :checked="selected === 'idcard'" />
    <ListItemCheck :text="$locale['passport_label']" @click="$emit('select', 'passport')" :checked="selected === 'passport'" />
    <ListItemCheck :text="$locale['drive_license_label']" @click="$emit('select', 'drivelic')" :checked="selected === 'drivelic'" />
  </div>
</template>

<script>
export default {
  props: ["selected"],
};
</script>

<style lang="scss" scoped>
.id-type {
  border: solid 1px $alto;
  overflow: hidden;
  border-radius: $mpadding;
  margin: $mpadding 0;
  .list-item {
    padding: $mpadding;
    &:not(:first-child) {
      border-top: solid 1px $alto;
    }
  }
}
</style>
